import request from '@/common/request';
/**
 * 列表
 */
export function GetList(data) {
  return request({
    url: '/clinic/index/getNotificationTabPage',
    method: 'GET',
    params: data
  });
}
