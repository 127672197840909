<template>
  <div class="bottom">
    <div class="bottom_div">
      <div class="txt_news"><span>消息</span></div>
      <a-list item-layout="horizontal" :data-source="list">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-list-item-meta :description="item.content">
            <span slot="title">订单通知 <span style="font-weight: normal;font-size: 12px;color: #666666">({{item.create_time}})</span></span>
            <a-avatar
              slot="avatar"
              src="https://medibook-static.oss-cn-hangzhou.aliyuncs.com/order.png"
            />
          </a-list-item-meta>
        </a-list-item>
      </a-list>
      <div
        style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 20px;
      "
      >
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="page.limit"
          @current-change="changePage"
          :current-page="page.start"
          :total="page.totalRow"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
      },
      list: [
      ],
    };
  },
  mounted() {
    this.getList();
    this.$store.commit('SET_MESSAGE_UNREAD', false)
  },
  // 函数
  methods: {
    /**
     * 切换订单分页
     * @author wheat
     * */
    changePage(e) {
      this.page.start = e;
      this.getList();
    },
    async getList() {

      const res = await Api.GetList({ });
      if (res.code == "0") {
        this.page.pageSize = res["page"]["pageSize"];
        this.page.totalRow = res["page"]["totalRow"];
        this.list = res["page"]["list"];
      } else {
        this.$message.error(res.message);
      }

    }
  },
};
</script>
<style lang="less">
@import "../../store/css/CSS-Initialization.css";
@import "../../store/css/general.less";
.txt_news {
  display: flex;
  border: 0;
  .txt_news_txt {
    padding-left: 14px;
    border-left: 3px solid #da8319;
    height: 18px;
    margin: auto 0;
  }
  div.finance_div {
    border: 0;
    display: flex;
    margin: 0;
    padding: 0;
    margin-left: auto;
    div.finance_div_bot {
      background-color: #e48a1b;
      color: white;
      font-size: 16px;
      padding: 8px 27px;
      margin-left: 12px;
      border-radius: 5px;
      letter-spacing: 4px;
    }
    div.finance_div_bot:last-child {
      background-color: #5f5f5f;
    }
  }
}
.search_information {
  display: flex;
  background-color: white;
  height: 48px;
  .search_information_txt {
    padding-left: 10px;
    display: flex;
    margin: auto 0;
    .search_information_img {
      margin: auto 0;
      display: flex;
      img {
        margin: auto 0;
      }
    }
    .search_information_txt {
      margin: auto 0;
      span {
        color: #e48a1b;
        margin-left: 24px;
      }
    }
  }
}
// .all .bottom .bottom_div div.txt_news {
//   border: 0;
//   padding: 0;
// }
</style>

